.loginPopWrap {display:flex; align-items:center; flex-direction:column; row-gap:30px; padding:20px; width:100%; box-sizing:border-box; line-height:1.5;}

.loginPopWrap [class*="icon"] {display:inline-block; background-repeat:no-repeat; background-position:center center; background-size:100% auto;}
.loginPopWrap .iconCheckbox {width:16px; height:16px; background-image:url('/images/icon/input-checkbox-default.svg');}

.loginPopWrap [class*="btnBlue"] {display:inline-block; width:100%; max-width:400px; border:0; background-color:#3C89F9; color:#fff; font-weight:400; line-height:1.5; border-radius:8px; box-sizing:border-box;}

.loginPopWrap .btnBlue {padding:12px 29px; height:48px; font-size:16px;}
.loginPopWrap .btnBlueLine {padding:12px 29px; height:48px; border:1px solid #3C89F9; background-color:transparent; font-size:16px; color:#3C89F9;}

.loginPopWrap [class*="btnDarkGray"] {display:inline-block; width:100%; max-width:400px; border:0; background-color:#555; color:#fff; font-weight:400; line-height:1.5; border-radius:8px; box-sizing:border-box;}

.loginPopWrap .btnDarkGraySmall {padding:5px 23px; width:auto; height:36px; font-size:12px;}

.checkBox {display:inline-block; position:relative; padding-left:30px; line-height:1.5;}
.checkBox input[type="checkbox"] {position:absolute; left:0; top:0; opacity:0;}
.checkBox .iconCheckBox {position:absolute; left:0; top:50%; width:24px; height:24px; background-image:url('/images/icon/input-checkbox-default.svg'); transform:translateY(-50%); background-size:16px auto;}
.checkBox input[type="checkbox"]:checked + .iconCheckBox {background-image:url('/images/icon/input-checkbox-abled.svg');}
.checkBox .checkLabel {display:block; font-size:16px; color:#000; font-weight:400;}

.loginThumb {display:flex; align-items:center; gap:20px; width:100%;}
.loginThumb .ltImgWrap {width:50%;}
.loginThumb .ltImg {overflow:hidden; position:relative; padding-top:56.27%; width:100%; font-size:0; line-height:0; border-radius:8px;}
.loginThumb .ltImg > img {position:absolute; left:50%; top:50%; width:100%; transform:translate(-50%, -50%); object-fit:cover; object-position:center;}
.loginThumb .txtGuide {display:inline-block; max-width:50%; font-size:16px; color:#000; font-weight:400;}

.loginGuie {display:flex; align-items:flex-start; flex-direction:column; row-gap:5px; width:100%;}
.loginGuie .txtDesc {display:block; position:relative; padding-left:10px; font-size:14px; color:#555; font-weight:400; text-align:left;}
.loginGuie .txtDesc:before {content:''; position:absolute; left:0; top:8px; width:4px; height:4px; background-color:#555; border-radius:2px;}


.buttonGroup {display:flex; align-items:center; gap:20px; width:100%;}
.buttonGroup .btnBlue,
.buttonGroup .btnBlueLine {width:50%;}

.todayCheck {display:flex; align-items:center; gap:20px; width:100%; }
.todayCheck .checkBox {margin-right:auto;}
.todayCheck .checkBox .checkLabel {font-size:14px; color:#555;}
.todayCheck .btnDarkGraySmall {margin-left:auto;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
  /* .processContGroup {padding:0 20px;} */
  .processContGroup .swiper-slide {width:100% !important;}
  .loginPopWrap {padding:20px 0;}
  .buttonGroup .btnBlue,
  .buttonGroup .btnBlueLine {padding:12px;}
}