/* 메인 비주얼 로그인 전용 컨텐츠 */
.loginContentsGroup {
  overflow:hidden;
  width: 100%;
  height: 100%;
  background: #3c89f9;
  position: relative;
}
.loginContentsGroup:before,
.loginContentsGroup:after {
  content:'';
  position:absolute;
  top:0;
  width:100%;
  height:100%;
}
.loginContentsGroup:before {
  right:50%;
  margin-right:700px;
  background-image: linear-gradient(to right, #C5FFBA 80%, #3C89F9);
}
.loginContentsGroup:after {
  left:50%;
  margin-left:700px;
  background-image: linear-gradient(to left, #4046C0 80%, #3C89F9);
}
.loginContentsGroup .contArea {
  width: 100%;
  max-width: 1512px;
  margin: 0 auto;
}
.loginContentsGroup .visualTit {
  width: 100%;
  padding-top: 60px;
  /*margin: 0 0 80px 0;*/
  margin:0 0 32px 0;
}
.loginContentsGroup .visualTit h2 {
  width: 100%;
  font-size: 38px;
  font-weight: bold;
  line-height: 1.47;
  letter-spacing: -0.5px;
  color: #ffffff;
}
.loginContentsGroup .visualTit h2 .num {
  padding: 2px 6px 1px 7px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: bold;
  line-height: 44px;
  letter-spacing: -0.29px;
  color: #3c89f9;
  vertical-align: top;
  border-radius: 11px;
  background: #fff;
}
.loginContentsGroup .visualCont {
  /*
  -webkit-mask-image: linear-gradient(to right, transparent 0%, transparent 3%, black 10%, black 90%, transparent 97%, transparent 100%);
  */
}
.loginContentsGroup .visualCont .swiper-container {
  padding: 0 140px;
}
.loginContentsGroup .visualCont .subjectCard {
  width: 212px;
}
.loginContentsGroup .visualCont img {
  width: 100%;
}
.loginContentsGroup .pagenation {
  width: 100%;
  position: relative;
}
.loginContentsGroup .pagenation .btnPrev {
  width: 64px;
  height: 64px;
  font-size: 0;
  background: url(/images/icon/ico-64-arrow-right.svg) center no-repeat;
  opacity: 0.5;
  transform: rotate(180deg);
  position: absolute;
  left: 0px;
  bottom: 115px;
  z-index: 10;
  transition: opacity 0.3s;
}
.loginContentsGroup .pagenation .btnNext {
  width: 64px;
  height: 64px;
  font-size: 0;
  background: url(/images/icon/ico-64-arrow-right.svg) center no-repeat;
  opacity: 0.5;
  position: absolute;
  right: 0px;
  bottom: 115px;
  z-index: 10;
  transition: opacity 0.3s;
}
.loginContentsGroup .pagenation .btnPrev:hover {
  opacity: 1;
}
.loginContentsGroup .pagenation .btnNext:hover {
  opacity: 1;
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
  .visualList {
    height: 506px;
  }
  .loginContentsGroup > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 30px 0 70px;
  }
  .loginContentsGroup .visualTit {
    width: 100%;
    padding: unset;
    margin: unset;
  }
  .loginContentsGroup .visualTit h2 {
    width: 100%;
    font-size: 18px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
  .loginContentsGroup .visualTit h2 .num {
    padding: 2px 6px 1px 7px;
    font-size: 12px;
    font-weight: bold;
    line-height: 34px;
    letter-spacing: -0.16px;
    border-radius: 8px;
    vertical-align: middle;
  }
  .loginContentsGroup .visualCont {
    -webkit-mask-image: unset;
    padding: 0 20px;
  }
  .loginContentsGroup .visualCont .swiper-slide {
    width: 144px;
    padding-top: 26px;
    transition: width 0.5s, padding 0.5s;
  }
  .loginContentsGroup .visualCont .swiper-slide.swiper-slide-active {
    width: 212px;
    padding-top: 0;
  }
  .loginContentsGroup .pagenation {
    display: none;
  }
}

@media screen and (min-width: 100px) and (max-width: 640px) {
  /* 240715 메인 키비주얼 수강/대기 과정 수정 */
  .loginContentsGroup .visualCont .subjectCard {
    width: 96px;
  }
}
